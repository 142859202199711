<template>
  <div class="commissionAgreement_page">
    <p class="title">酷联盟服务协议</p>
    <p style="margin-bottom:0.30rem;">欢迎您使用<span>酷联盟服务！</span></p>
    <p class="indent">为您更好地接受<span>酷联盟服务</span>（暂定名<span>“酷联盟”</span>，下称“推广服务”或“服务”），亦为明确推广人的推广行为，明确各方享有的权利和应履行的义务， 请您仔细阅读、理解并遵守《酷联盟推广服务协议》（以下简称“本协议”）。</p>
    <p class="indent"><span>本协议由您与未序网络科技（上海）有限公司、西藏福禄网络科技有限公司共同缔结，对双方具有法律效力。请仔细阅读以下全部内容，特别是以黑体加粗或其他合理方式提示您注意的条款，该等条款可能与您的权益存在重大利害关系，包括但不限于优酷责任、您享有的权利、争议解决方式及司法管辖条款等，请您留意重点阅读。如果您认为本协议中加粗条款可能导致您的部分或全部权利或利益受损，请您在确认同意本协议之前或在使用本服务之前务必再次阅读前述条款，并在您自愿接受该等条款的前提下使用本服务。</span></p>
    <p class="indent"><span>如果您不同意本协议的任一或全部条款内容，请不要以确认的形式（包括但不限于：点击、申请推广、进入下一步程序等）进行下一步操作或使用本协议项下服务。当您确认接受本协议或开始使用本协议项下服务时，即表示您与酷联盟已达成协议关系，并自愿接受并遵守本协议项下的全部约定。如果您对本协议的条款有疑问，您可通过页面在线客服二维码进行咨询。</span></p>
    <p class="indent"><span>酷联盟有权根据自身的业务需要不定期更新本服务协议的内容，如本协议发生变更， 将在推广活动相关页面或以酷联盟页面公告、站内通知、push的任一一种方式予以公布，公布后生效。若您在协议生效后继续使用本服务的，表示您接受变更后的协议，也将按照变更后协议的约定使用本服务。如您拒绝接受变更后的协议内容，您须暂停继续使用酷联盟提供的相应部分或全部服务。</span></p>
    <p class="indent"><span>本协议仅针对 18周岁以上且具备完全民事行为能力的自然人签署，且若您是未成年人或限制民事行为能力人，本协议不予生效。 </span></p>
    <p class="title1">一、服务说明</p>
    <p>1、本协议所称“酷联盟推广平台服务方”是指未序网络科技（上海）有限公司公司及其指定运营方：西藏福禄网络科技有限公司。</p>
    <p>2、本项协议所称“酷联盟推广服务”是指：未序网络科技（上海）有限公司公司及其指定的运营方提供给用户使用的推广优酷/酷喵会员或其他指定产品相关的推广和结算服务。推广人可通过专属二维码/专属链接成功邀请其他用户购买优酷/酷喵会员会员产品或其他产品，并获得相应佣金。推广活动方案，包括但不限于推广时间、推广产品、佣金比例及结算方式以届时酷联盟活动页面公示为准。</p>
    <p>3、本协议所称“您”或推广人是指：注册或使用推广人推广服务的用户，本协议仅构成您使用推广服务的关系，在任何情况下并不视为您与酷联盟、优酷或优酷会员产品或推广的其他产品之间有任何代言、控股、合伙、投资、代理等关系。</p>
    <p>4、本协议所称“推广代码”是指： 分配给各个推广人的唯一标识其推广身份或推广产品的代码、二维码等。</p>
    <p>5、本协议所称“推广对象”指：优酷/酷喵VIP会员产品或其他届时的活动页面展示的推广产品。 </p>
    <p>6、本协议所称“运营方”是指：西藏福禄网络科技有限公司。</p>
    <p class="title1">二、  协议订立、生效和适用范围</p>
    <p>1、 您需同意并确认本协议，且为实现推广人佣金数据同步等功能，您在申请开通推广服务权限之前应当绑定优酷账户，方可申请成为推广人，进行推广活动。您点击页面同意按钮，或未确认本协议及及完成绑定优酷账号，或实际使用本协议下服务的，均表示您与平台方已经就本协议达成一致并同意接受本协议的全部约定内容。</p>
    <p>2、 本协议自您确认本协议之时即时生效。若您未确认本协议，但实际使用本协议下服务或实际履行本协议的，协议自您实际使用或履行之日起生效。</p>
    <p>3、 本协议包括本协议正文以及所有在酷联盟页面已经发布或者将来可能发布的各类规则、规范、通知等。规则、规范及通知等作为本协议的组成部分，与本协议具有同等法律效力。</p>
    <p class="title1">三、准入资格及服务开通</p>
    <p>1、您需同时具备以下条件：</p>
    <p><span>（1）18周岁以上且具备完全民事行为能力的自然人；</span></p>
    <p>（2）注册酷联盟账号及优酷账号、且账号为正常状态；</p>
    <p>（3）其他酷联盟要求的资格条件。</p>
    <p>2、符合上述条件的用户，酷联盟将根据用户申请，开通相应的推广服务。若您开通推广服务权限的酷联盟账号或优酷账号因违规、资格条件不具备或其他原因被暂停使用或终止使用时，则相应的推广服务暂停使用或终止使用。 您拥有账户的有限使用权，只限于自身使用，不得转让、出借、出租，并妥善保管相关账号密码。</p>
    <p><span>3、您加入推广服务无需额外缴纳任何费用，您也不得以任何理由向您推荐的用户收取任何形式的加盟费或其他费用。您在此确认任何收取加盟费用或其他任何费用的行为均与酷联盟平台、推广品牌优酷无关，若您的行为导致其他任何方遭受财产等损失的，您需自行与被收费人或其代理人解决。</span></p>
    <p><span>4、如发现您利用推广服务向第三方收取类似加盟费或其他费用的，酷联盟有权立即取消或封停您的推广人资格，扣除该账号下的佣金，甚至要求您退还该账号下已经获得收益，由此导致酷联盟、优酷或用户等任何第三方损失的，您应该负责全额赔偿。涉嫌构成犯罪的，酷联盟有权将案件情况移送司法机构处理。</span></p>
    <p class="title1">四、 推广服务内容</p>
    <p>1、以下任何一项服务的开通，都可以描述为推广服务的使用。但具体服务的内容，均以所开通的服务支持的服务内容为准：</p>
    <p>1）生成与推广账户相关的推广二维码/链接；</p>
    <p>2）佣金、结算数据展示等；</p>
    <p>3）相关结算账户的操作功能。</p>
    <p>2、您了解并同意，推广服务内容具体以酷联盟实现功能为准，且酷联盟随时可能根据业务的发展或系统功能的调整等增加或修改服务内容，并就调整的内容通过系统公告、系统更新提醒或其他合理方式告知您，如您对更新内容有异议的，可以选择不再继续使用本服务，双方可解除合作。如您继续使用更新后的服务内容的，视为您同意并接受上述更新内容。</p>
    <p class="title1">五、 佣金和结算</p>
    <p>1、 您使用推广服务且不违反本协议时，有权根据本协议及《酷联盟成员佣金与行为规则》获得相应的佣金。</p>
    <p><span>2 、佣金比例：佣金比例以活动页面展示为准。平台服务方有权调整不同活动的佣金比例。且，您通过推广二维码/链接引导的有效购买将被酷联盟系统记录下来作为与您结算的依据。您知悉并同意，所有结算依据以酷联盟系统的判断及出具的结果为准。</span></p>
    <p><span>3、佣金适用范围：佣金为推广人直接推广优酷会员卡或其他推广商品的销售提成，但被推广人自购订单、自动续费产品订单、非通过直接分享的专属二维码或链接付款订单、退费订单或通过作弊、黑灰产等方式获得的订单不参与返佣。</span></p>
    <p><span>4、佣金形式：现金，具体活动方案以届时活动页面说明为准。</span></p>
    <p><span>5、结算周期：推广人推广有效订单生效后，佣金将进入待结算状态；有效订单生效后15个自然日进入可提现状态，每月的10号和15号推广人可以对可提现状态下的金额发起提现申请。系统审核通过后，通常情况下，会在10个工作日内向推广人打款。</span></p>
    <p><span>佣金支付由运营方西藏福禄网络科技有限公司负责，西藏福禄通过其合作方：武汉福穗网络科技有限公司作为支付主体向推广人在酷联盟申报的收款账号打款。</span></p>
    <p><span>您亦理解并同意酷联盟根据实际运营需要变更或新增合作方，实际变更和新增的公司以实际支付佣金结算款的汇款公司为准。 </span></p>
    <p><span>若错过当月申请结算日期，当月佣金将自动累计至下次结算。若本服务关停，最长给推广人一个月时间提走全部推广费用，否则佣金清零后果由推广人自行承担。</span></p>
    <p>6、为了您能够顺利提现之必要，您授权酷联盟可以将您的个人信息（包括但不限于手机号（需为以您自己名义申请的手机号）、身份证号、姓名、身份证正反面照片、银行卡号、提现结算金额、开户银行名称等）传递给武汉福穗网络科技有限公司用于结算的身份核对及汇款工作，但保证仅会出于合法、正当、必要、特定、明确的目的传递您的个人信息，并且只会传递必要的个人信息。在传递信息时，酷联盟及合作方会以多种方式保护您的个人信息，包括但不限于在必要时会于页面提示上向您展示传递信息的目的、类型等；严格要求各方的信息保护义务与责任；对信息数据的输出形式、授权字段等进行安全评估与处理（如加密、专线传输等）。且基于为您提供更好的服务的目的，在酷联盟为您提供相关服务的服务期内或/和服务期结束后，酷联盟有权保留并使用您在享受本协议项下的服务期间所形成的相关信息（包括但不限于您在本协议下提供给第三方的信息等）。</p>
    <p><span>7、您同意并确认，合作方向您支付佣金时将由合作方代为缴纳您个人应承担的相关税费。</span></p>
    <p><span>8、优酷会员卡或其他推广产品一经售出不予退换，若通过您推广所售出的会员产品出现退款，则不计入佣金范围。如每个结算周期退款数占其整体推广订单数超过一定比例（超过3%），或有虚假邀请好友，虚假交易行为，酷联盟有权直接判定该您存在作弊刷单行为，您的本结算周期佣金将不予结算，且酷联盟有权终止您的推广资格。如退费发生前已经向您结算佣金的，已经结算的部分您需全额退还。</span></p>
    <p>9、在使用推广服务或在推广过程中，一切硬件、软件、服务和其他费用成本，均由推广人自行承担。</p>
    <p>10、佣金具体规则见《 酷联盟成员佣金与行为规则》。</p>
    <p class="title1">六  推广人行为限制</p>
    <p>1、 您在注册和提交信息时，应保证提供的身份信息/单位相关信息和填写的相关信息的真实性、准确性和合法性，同时，您在使用酷联盟服务过程中，若相应信息发生变更的，应及时进行更新，若因您提供虚假不实信息或未及时更新信息，导致任何损失的，由您自行承担，同时酷联盟有权关停其服务账户、单方面停止合作。</p>
    <p>2、您应妥善保管其开通推广服务的账号及密码，在不使用服务时应使其处于退出状态，同时您不得将账号出租、转让或授予他人使用，所有通过推广人账号进行的行为均被视为您自身的行为。</p>
    <p>3、您应遵守平台发布的各项规则，若不同意某项规则，应及时停止使用服务。若您在平台发布新规则后，继续使用服务，则视为您同意该规则，愿意接受该规则约束。</p>
    <p>4、您应按照法律法规的规定及平台的相关要求使用推广平台服务。若您在使用服务过程中有违法违规行为，被行政或司法机关认定为违法或侵权，或根据优酷自身判断，认为您行为存在违法违规行为的，优酷有权按照本协议关停其推广账户、单方面停止合作，同时，若因您行为给酷联盟、优酷造成任何损失、被第三方索赔、被用户投诉或被行政机关处罚的，您应赔偿平台因此遭受的所有损失和费用，包括但不限于经济损失、赔偿金、罚金、合理的律师费、给第三方的赔偿金和其他合理支出等，并负责消除对优酷造成的不利影响。</p>
    <p><span>5、您在使用推广服务时，应遵循下列约定：</span></p>
    <p><span>（1） 不违反国家相关法律法规的规定、不违反自身与第三方签署的协议/约定的义务和平台的相关要求，诚实守信，遵守社会公共秩序和道德，不损害他人合法利益。您若违反上述约定造成任何损害后果的，均由您独立承担全部法律责任，包括赔偿因此给第三方或酷联盟、优酷造成的损失。</span></p>
    <p><span>（2） 不得擅自修改其推广代码，或通过技术手段将推广代码植入被推广客户端、网站或与被推广对象相关的其他软件或网站，或利用软件、产品内置恶意、捆绑SP服务（“恶意SP服务”指：任何未向用户明示且未经用户同意即可自行扣费、消费、吸费等造成用户费用损失之代码、插件、链接、后台以及客户端软件等）。</span></p>
    <p><span>（3） 不得采取任何欺骗或虚假的手段进行推广，以获取不真实的推广效果数据。</span></p>
    <p><span>（4） 不得采取欺骗、诱导、误导、强制或其他有损被推广对象声誉的行为，或者与被推广对无关的内容向目标用户推广，包括但不限于强制消费、暴力发送短信和推广信息、使用恶意软件捆绑安装、强制安装消费、利用侵权或色情暴力等无关内容进行推广、利用返点等给予现金的方式诱导用户消费等违反用户自由选择权的行为。</span></p>
    <p><span>（5） 不得采取不正当手段损害其他推广者的正常推广行为，不得扰乱酷联盟制定的推广市场秩序。</span></p>
    <p><span>（6） 不得采取技术手段或其他黑客措施攻击、更改推广平台服务器及数据，或采取其他措施干扰推广平台服务器正常工作。</span></p>
    <p><span>（7） 不得对被推广代码进行任何形式的改写、植入代码、病毒或改变其功能结构的行为。您须严格按照被推广对象的原始代码特征进行推广，不得改变或插入其他非被推广对象本身特征的代码或标识。</span></p>
    <p><span>（8）不得存在作弊刷单行为，关于作弊刷单行为酷联盟系统拥有判定权；刷单：指推广人自行或唆使他人通过自身二维码反复购买并且要求退款的行为。</span></p>
    <p><span>（9）未经书面允许，不得以优酷品牌或酷联盟推广服务及其相关名义进行推广；</span></p>
    <p><span>（10）您应维护与提高酷联盟、优酷和优酷产品的形象，不得作出、散播任何有损或与事实不符的关于优酷或优酷产品的言论或行为；</span></p>
    <p><span>（11）不得从事其他任何损害酷联盟、优酷利益或其他第三方合法权益的行为。</span></p>
    <p>6、未经平台服务方或优酷同意，您不得使用优酷的相关标识、商标，不得声称为推广平台或其相关服务的代理商、或有其他控制关系等，不得采取使用户误以为其为优酷工作人员或与推广平台具有某种关系（控股、合伙、投资、联盟、代理等）的行为，若因您违反上述规定，造成优酷或第三方利益受损的，推广平台有权终止其您资格，并有权就该行为造成的损失向您追偿，包括但不限于经济损失、赔偿金、罚金、合理的律师费用、给第三方的赔偿金和其他合理支出等，
      并负责消除对优酷造成的不利影响。</p>
    <p>7、您应对因本协议知悉或获取的优酷的所有信息、材料（包括但不限于价格、价格体系、市场推广计划、会员人数、优惠政策等）等严格保密，不得未经优酷授权自行使用或授权任何第三人使用、传播等。</p>
    <p class="title1">七、违规处理</p>
    <p>1、若您违反本协议或推广服务发布的各项规则的相关规定的，您同意酷联盟有权根据您违规行为的性质、情节轻重、过错程度等，按照自身独立的判断及推广系统统计的数据（如有），对您采取下列一项或多项处理措施：</p>
    <p>（1） 给予警告；</p>
    <p>（2） 限期整改；</p>
    <p>（3） 暂停某一或全部服务功能的使用；</p>
    <p>（4） 不予结算涉嫌违规行为涉及的部分或全部佣金</p>
    <p>（5） 不予结算或直接扣除推广服务账户下已产生及当月将要产生的部分或全部佣金</p>
    <p>（6） 停止向您提供推广服务，并扣除服务账户下全部未结算费用；</p>
    <p>（7） 限制您再次成为推广服务用户；</p>
    <p>（8） 要求您进行损失赔偿</p>
    <p>（9）停止向您提供优酷账号服务。</p>
    <p>就您违规给平台服务方、优酷及第三方造成的损失，通过上述措施仍不能弥补的，平台服务方保留向您追偿的权利，您的赔偿范围包括但不限于平台服务方、优酷为此支出的所有费用及开支。</p>
    <p>2、若您对处理违规措施有异议的，可在平台给出处理措施后5个工作日内，通过申诉途径（暂定为客服渠道）提供相反的证据材料进行申诉。申诉不影响已给出处理措施的执行。逾期未申诉的，视为您同意相关处理结果。</p>
    <p class="title1">八、责任范围和限制</p>
    <p>1、推广服务将按"现状"和"可得到"的状态提供。酷联盟在此明确声明对推广服务不作任何明示或暗示的保证，包括但不限于推广服务的可适用性、没有错误或疏漏、持续性、准确性、可靠性、适用于某一特定用途。</p>
    <p>2、在所适用的法律允许的范围内，平台服务方不做任何明示的或默示的声明，也不给予任何明示的或默示的保证或条件，包括但不限于：</p>
    <p>（1） 关于适销性、特定用途适用性、准确性和无侵权行为的任何保证或条件；</p>
    <p>（2） 在交易过程或行业惯例中产生的任何保证或条件；</p>
    <p>（3） 在使用推广服务时不受干扰、没有错误的任何保证或条件。</p>
    <p>3、推广服务不对源于、基于或因本协议或使用推广服务而导致的数据丢失或任何损害赔偿负责，包括但不限于直接的、间接的、特殊的、附带性的或惩罚性的损害赔偿或其他任何形式的损害赔偿，即使优酷已被告知此等损害赔偿的可能性。</p>
    <p>4、对您的任何通知，不论是口头还是书面的，均不表示优酷做出在本协议陈述以外的任何担保责任。</p>
    <p>5、对下列情形，酷联盟不承担任何责任：</p>
    <p>（1）并非平台的故意或过失而导致服务变更、中断或无法使用，包括但不限于：</p>
    <p>鉴于网络服务的特殊性，您同意酷联盟会变更、中断部分或全部的网络服务，并删除（不再保存）您在使用推广服务中提交的任何资料，而无需通知您，也无需对您或第三方承担责任。</p>
    <p>您理解，酷联盟需要定期或不定期地对提供推广平台服务的平台或相关的设备进行检修或者维护，如因此类情况而造成酷联盟服务在合理时间内的中断，酷联盟无需为此承担任何责任。</p>
    <p>（2）第三方未经批准使用您的账户或更改您的数据。</p>
    <p>（3）您对推广服务的误解所致的损失。</p>
    <p>（4）因酷联盟合理控制范围以外的原因，包括但不限于由于信息网络正常的设备维护，信息网络连接故障，电脑、通讯或其他系统的故障，电力故障，罢工，劳动争议，暴乱，起义，骚乱，生产力或生产资料不足，火灾，洪水，风暴，爆炸，战争，政府行为，司法行政机关的命令或第三方的不作为而造成的不能服务或延迟服务。</p>
    <p>（5）因您履行本协议而导致的对法律法规的违反、第三人合法权益的侵犯、以及对与第三人之间的协议/约定的违反而导致的任何损害、纠纷，由您自行解决与承担责任，与酷联盟和推广服务无关，并使酷联盟免责。</p>
    <p class="title1">九、推广人推广服务的中止/终止</p>
    <p>1、酷联盟有权随时中止或终止向您提供部分或者全部推广服务，包括暂时关闭或者永久关闭您的推广服务，撤销活动页面，且无须为您或者第三方承担责任。服务自向您发出通知或公告之日起终止。</p>
    <p>2、在下列情况下，酷联盟有权终止向您提供推广服务，本协议终止，您在酷联盟的账户永久关闭：</p>
    <p>（1）在您违反本协议的相关规定时，酷联盟有权终止向该您提供服务;</p>
    <p>（2）发现您的行为、数据或者信息含有虚假内容;</p>
    <p>（3）本协议终止或者修订中，您明示不愿意接受新的服务协议;</p>
    <p>（4）其他酷联盟认为需要终止服务的情况。</p>
    <p>3、因国家或相关政府监管部门要求或发生不可抗力事件或其他法律法规要求时，酷联盟有权中止或终止服务；</p>
    <p><span>4、除法律规定的责任外，酷联盟对本服务的中止或终止对您和第三人不承担任何责任。</span></p>
    <p><span>5、服务终止后，酷联盟没有义务为您保留与其结算相关的信息，或为其转发任何未曾阅读或发送的信息给第三方或您。服务终止后，您留存在推广服务活动页面的数据，酷联盟有权按照国家相关规定保存或不保存，对此并不向您承担任何义务。</span></p>
    <p><span>6、无论本协议因何种原因终止，在协议终止前的行为导致的任何赔偿和责任，您必须完全且独立地承担责任。</span></p>
    <p class="title1">十、知识产权</p>
    <p>1、联盟平台、推广平台、客户端及应用上的所有内容，包括但不限于著作、图片、LOGO、商标、文字、档案、资讯、资料、网站架构、画面的安排、网页设计均由优酷或其他权利人依法拥有知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。未经优酷或其他权利人许可，任何人不得擅自使用、修改、复制、公开传播、改变、散布、发行或公开发表前述有知识产权的程序或内容。</p>
    <p>2、 联盟平台名称或标识均受到商标法及其他相关法律法规的保护，任何人未经授权不得使用、复制或用作其他用途。您在推广过程中，如需使用这些商标或标识，应征得权利人的同意，否则即构成侵权，应承担侵权责任。</p>
    <p class="title1">十一、个人信息保护</p>
    <p><span>1、平台非常重视您个人信息的保护，在您使用平台的服务时，您同意按照在平台公布的隐私权政策收集、存储、使用、披露和保护您的个人信息。平台服务方希望通过隐私权政策向您清楚地介绍平台对您个人信息的处理方式，因此优您完整地阅读隐私权政策，以帮助您更好地保护您的隐私权。 更多关于个人信息处理和保护规则、用户对个人信息的控制权等内容，请查阅《优酷隐私政策》。</span></p>
    <p><span>2、 为了您能够顺利提现， 可能需要将您的个人信息（包括但不限于以自己身份办理的手机号、身份证号、姓名、身份证正反面照片、收款银行卡信息、提现结算金额等）提供给武汉福穗网络科技有限公司。但酷联盟和武汉福穗网络科技有限公司仅会出于合法、正当、必要、特定、明确的目的收集、使用您的个人信息。在收集、使用信息时， 平台会以多种方式保护您的个人信息。 </span></p>
    <p><span>3、酷联盟非常注重未成年人的保护。若用户未满18周岁，则为未成年人，请不要使用本服务。</span></p>
    <p class="title1">十二、争议解决和法律适用</p>
    <p>1、本协议条款的生效、履行、解释及争议的解决均适用中华人民共和国法律，本协议条款因与中华人民共和国现行法律相抵触而导致部分无效，不影响其他部分的效力。</p>
    <p>2、如就本协议内容或其执行发生任何争议，应尽量友好协商解决；协商不成时，任何一方均可向被告所在地人民法院提起诉讼。</p>
    <p class="title1">十三、联系我们</p>
    <p>如您对本协议或使用服务的过程中有任何问题（包括问题咨询、投诉等），您可通过页面的二维码与客服联系。</p>
    <p class="title1">十四、其他</p>
    <p>1、本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。</p>
    <p>2、本协议的标题仅为方便及阅读而设，并不影响正文中任何条款的含义或解释。</p>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  }
}
</script>
<style lang="less" scoped>
.commissionAgreement_page {
  min-height: 100vh;
  background: #f5f6f8;
  padding: 0.30rem;
  line-height: 18px;
  font-size:14px;
  color:#555;
  .title{
    text-align: center;
    font-size:16px;
    color:#000;
    font-weight: bold;
    margin-top: 0.30rem;
    margin-bottom: 0.60rem;
  }
  p{
    margin-top:0.05rem;
    span{
      font-weight: bold;
      color:#000;
    }
  }
  .title1{
    color:#000;
    font-weight: bold;
    margin-top:0.20rem;
  }
  .indent{
    text-indent:0.70rem;
  }
}
</style>
